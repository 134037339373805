import React from 'react'
import { graphql } from 'gatsby'
import { PageLayout, BrandListPage } from '../../components'

const BrandsListTemplate = ({ data, pageContext }) => {
  const { lang, page } = pageContext

  const brands = data.allShopBrand
    ? data.allShopBrand.edges.map(({ node }) => node)
    : []

  return (
    <PageLayout lang={lang} switcher={page}>
      <BrandListPage brands={brands} {...pageContext} />
    </PageLayout>
  )
}

export default BrandsListTemplate

export const query = graphql`
  query BrandsListTemplateQuery {
    allShopBrand(filter: {active: {eq: 1}, hidden: {eq: 0}}) {
      edges {
        node {
          ...BrandItem
        }
      }
    }
  }
`
